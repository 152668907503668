import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var imaToolsData = [{
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Arabic',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Arabic.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Catalan',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Catalan.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities English',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-English.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Italian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Italian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities German',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-German.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Hungarian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Hungarian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Japanese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Japanese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Korean',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Korean.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Portuguese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Portuguese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Romanian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Romanian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Russian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Russian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Spanish',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Spanish.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Vietnamese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Vietnamese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Arabic',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Arabic.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Catalan',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Catalan.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks English',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-English.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Italian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Italian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks German',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-German.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Hungarian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Hungarian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Japanese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Japanese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Korean',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Korean.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Portuguese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Portuguese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Romanian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Romanian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Russian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Russian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Spanish',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Spanish.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks Vietnamese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Leadership-Program-Ranks-Vietnamese.pdf'
}];